#become-certified {
  .chakra-stat__number {
    display: flex;
    align-items: center;
    img {
      height: 30px;
      width: auto;
      margin: 5px 5px 5px 0;
      filter: invert(1);
    }
  }
}
