#patients {
  &.labs-detail {
    table {
      margin-bottom: 2em;
      td:first-child {
        max-width: 60px;
        text-transform: uppercase;
        font-size: 0.75em;
        font-weight: 600;
      }
    }
  }
  tr {
    //cursor: pointer;
    &:hover {
      td {
        background-color: rgba(217, 224, 255, 0.05);
      }
    }
  }
  .status {
    text-transform: capitalize;
  }
  .id {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mark {
    img {
      height: 20px;
      width: 20px;
    }
  }
}
