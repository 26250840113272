#landing {
  .container-hazan {
    @media screen and (max-width: 768px) {
      padding: 15px !important;
    }
  }
}

.conference {
  height: 100vh;
  overflow: hidden;

  #landing-hazan {
    .container-hazan {
      display: flex;
      flex-direction: column;
      height: 100vh;
      justify-content: center;
      padding: 0 !important;
    }
  }
  #hero {
    text-align: center;
  }
  .chakra-stack {
    align-items: center;
    justify-content: center;
  }
}
