#discussions {
  //max-width: 800px;
  margin: auto;
  .preview {
    max-height: 80px;
    overflow: hidden;
    width: 100%;
    white-space: break-spaces;
  }
  .author {
    display: flex;
    align-items: center;
    font-size: 0.75em;
    font-weight: 700;
    margin: 0.5em 0 1em;
  }
  .avatar {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
  .divider {
    margin: 0 15px;
  }
  &.labs-detail {
    table {
      margin-bottom: 2em;
      td:first-child {
        max-width: 60px;
        text-transform: uppercase;
        font-size: 0.75em;
        font-weight: 600;
      }
    }
  }
  tr {
    cursor: pointer;
    &:hover {
      td {
        background-color: rgba(217, 224, 255, 0.05);
      }
    }
  }
  .status {
    text-transform: capitalize;
  }
  .id {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .note {
    img {
      margin: auto;
      height: 20px;
      width: 20px;
    }
  }
}
