#onboarding {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  max-width: 800px;
  padding: 16px;
  text-align: center;
  height: 100vh;
  margin: auto;

  .chakra-heading {
    margin-top: -2em;
  }

  .chakra-heading,
  .chakra-text {
    margin-bottom: 0.5em;
  }

  @media screen and (max-width: 767px) {
    .chakra-heading {
      margin-top: -1.25em;
    }

    .chakra-text {
      margin-bottom: 0;
    }
  }
}
