#membership-types {
  padding: 2em;
  max-width: 1600px;
  margin: auto;
  .css-8t0gfa {
    width: 33%;
  }
  @media screen and (max-width: 768px) {
    .css-8t0gfa {
      width: 100%;
    }
  }
}
