@import url('https://fonts.googleapis.com/css2?family=Contrail+One&display=swap');

#app {
  position: relative;
  .trademark {
    font-family: 'Contrail One', sans-serif;
    font-weight: 600;
    vertical-align: top;
    font-size: 0.75em;
  }
  a {
    cursor: pointer;
  }

  .page-padding {
    padding: 1em;
    min-height: 50vh;
  }
}

input.filled {
  background-color: white !important;
  border: 1px solid var(--chakra-colors-chakra-border-color);
  color: black;
}

.ql-editor {
  min-height: 300px;
  height: fit-content;
}
