#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}

#hero {
  overflow: hidden;
  position: relative;

  button {
    font-size: 1.5em;
    padding: 1.25em;
  }

  .chakra-heading,
  .chakra-text {
    //text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 160vw;
    height: 160vh;
    z-index: -1;
    object-fit: cover;
  }
}
